import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="お問い合わせありがとうございました" />
      <div className="flex justify-center items-center p-5 md:p-20 bg-gray-50">
        <h1 className="sm:text-3xl text-2xl font-medium text-gray-600">
          お問い合わせありがとうございました
        </h1>
      </div>
      <div className="container mx-auto max-w-4xl p-5 md:p-10">
        <p className="leading-relaxed">
          3営業日以内に担当者よりご連絡いたします。
        </p>
        <p>
          お急ぎの場合は
          <a href="tel:0272434881">
            <span className="font-tel text-xl">027-243-4881</span>
          </a>
          までお電話いただけますと幸いです。
        </p>
      </div>
    </Layout>
  );
};

export default ContactPage;
